import i18n from "./i18n";
import ReactDOM from "react-dom/client";
import "./styles/tailwind.css";
import * as serviceWorker from "./serviceWorker";
import React from "react";
import Router from "./router";
import { Toaster } from "react-hot-toast";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";
import { UserProvider } from "./context/useContext";
import setupGlobalErrorHandler from "./utils/globalErrorHandler";
import { setupAuthInterceptor } from "./utils/setupAuthInterceptor";
import { AuthProvider } from "./context/useAuthContext";
import { BrowserRouter } from "react-router-dom";
import SentryErrorBoundary from "./components/SentryErrorBoundary";
import axios from "axios";
if (process.env.REACT_APP_ENV === "production") {
  Sentry.init({
    dsn: "https://7cd028f5db756bdc09f85107c65dcba6@o4507329664057344.ingest.de.sentry.io/4507329670414416",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
  const gtmTrackingId = "GTM-N8PWSQ2Q";

  if (gtmTrackingId) {
    const tagManagerArgs = { gtmId: gtmTrackingId };
    TagManager.initialize(tagManagerArgs);
  } else {
    console.log("Google Tag Manager tracking ID not found");
  }
}

i18n;
setupAuthInterceptor(axios);
setupGlobalErrorHandler();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <BrowserRouter>
      <SentryErrorBoundary>
        <UserProvider>
          <AuthProvider>
            <Router />
            <Toaster
              reverseOrder={true}
              gutter={5}
              containerClassName=""
              containerStyle={{}}
              toastOptions={{
                duration: 3000,
                style: {
                  background: "#F1F8F5",
                  color: "##212121",
                  width: 726,
                  height: 72,
                  maxWidth: "826px",
                  textAlign: "left",
                  left: "50%",
                },
              }}
            />
          </AuthProvider>
        </UserProvider>
      </SentryErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>
);

serviceWorker.unregister();
