import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  FC,
  useRef,
} from "react";
import useCognito from "../hooks/auth/useCognito";
import { useSubscriptionStore, useUserStore } from "../state";
import { useNavigate } from "react-router-dom";
import {
  CognitoUserSession,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import { useSubUserStore } from "../state/useSubUserStore";
import jwtDecode from "jwt-decode";
import { getCurrentMonthData } from "../utils/DateTimeRange";
import useGoogleAuth from "../hooks/auth/useGoogleAuth";
import { ROLES, STORAGE_REFRESH_TOKEN, STORAGE_TOKEN } from "../constants";
import Cookies from "js-cookie";
import { Modal } from "../components/common/Modal";
import { ChangePassword } from "../components/core/ChangePassword";
import BusinessTypeSelector from "../components/auth/BusinessTypeModal";
import { SubscriptionModal } from "../components/common/Subscription/SubscriptionModal";
import { useBudgetStore } from "../state/useBudgetStore";
import { DecodedToken, handleTokenAndFetchUserData } from "../utils/token";
import { useCompanyStore } from "../state/useCompanyStore";
import useUserDataLoader from "../hooks/users/useUserDataLoader";

interface AuthContextType {
  user: CognitoUserSession | null;
  loading: boolean;
  error: string | null;
  login: (username: string, password: string) => Promise<void>;
  googleLogin: () => void;
  handleGoogleCallback: () => Promise<void>;
  logout: () => void;
  register: (
    username: string,
    password: string,
    attributes: CognitoUserAttribute[],
  ) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

export const UseAuthContext = createContext<AuthContextType>({
  user: null,
  loading: false,
  error: null,
  googleLogin: () => {
    throw new Error("googleLogin function not implemented");
  },
  login: async () => {
    throw new Error("login function not implemented");
  },
  logout: () => {},
  register: async () => {
    throw new Error("register function not implemented");
  },
  handleGoogleCallback: async () => {
    throw new Error("handleGoogleCallback function not implemented");
  },
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const {
    user: cognitoUser,
    session,
    login: cognitoLogin,
    logout: cognitoLogout,
    register: cognitoRegister,
    loading: authLoading,
    error: authError,
  } = useCognito();

  const { handleGoogleSignIn, handleCodeExchange } = useGoogleAuth();
  const {
    fetchData,
    businessModalVisible,
    isPasswordModalShown,
    setBusinessModalVisible,
    setIsPasswordModalShown,
  } = useUserDataLoader();
  const { fetchBudgets } = useBudgetStore();

  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(authError);
  const { setUser, setRole } = useUserStore();
  const { setCompanyBankAccounts } = useCompanyStore();
  const { setSubscriptionData } = useSubscriptionStore();
  const isPasswordChanged = useSubUserStore((state) => state.isPasswordChanged);
  const isRootUser = useSubUserStore((state) => state.isRootUser);
  const hasGoogleCallbackExecuted = useRef(false);
  /**
   * Fetches user data after successful authentication.
   */

  // useEffect(() => {
  //   if (cognitoUser && session) {
  //     const token = session.getIdToken().getJwtToken();
  //     const decodedToken = jwtDecode<DecodedToken>(token);
  //     const userId = decodedToken["custom:custom_userid"];
  //     fetchData(userId);
  //   }
  // }, [cognitoUser, session]);

  useEffect(() => {
    if (cognitoUser && session) {
      const fetchDataAsync = async () => {
        const token = session.getIdToken().getJwtToken();
        const decodedToken = jwtDecode<DecodedToken>(token);
        const userId = decodedToken["custom:custom_userid"];
        await fetchData(userId);
      };
      fetchDataAsync();
    }
  }, [cognitoUser, session]);

  /**
   * Modified login function that authenticates with Cognito
   * and fetches user data upon successful authentication.
   * @param username
   * @param password
   */
  const login = async (username: string, password: string): Promise<void> => {
    setLoading(true);
    setError(null);
    try {
      const sessionResult: CognitoUserSession = await cognitoLogin(
        username,
        password,
      );
      const token = sessionResult.getIdToken().getJwtToken();

      const { userId, groups } = await handleTokenAndFetchUserData(
        token,
        setRole,
      );

      const userData = await fetchData(userId);
      await fetchBudgets();
      if (groups.includes(ROLES.ADMIN)) {
        if (!userData?.businessType || !userData.businessType.trim()) {
          navigate("/dashboard");
        } else {
          navigate("/dashboard");
        }
      } else if (groups.includes(ROLES.OPERATOR)) {
        navigate("/operator-dashboard");
      } else if (groups.includes(ROLES.ACCOUNTANT)) {
        navigate("/operator-dashboard");
      } else {
        setError("You do not have permission to access this application.");
      }
    } catch (err: any) {
      setError(err.message || "An unknown error occurred during login.");
    } finally {
      setLoading(false);
    }
  };
  /**
   * Modified logout function that signs out from Cognito
   * and clears user data from the store.
   */
  const logout = (): void => {
    cognitoLogout();

    setUser(null);
    setRole([]);
    setSubscriptionData(null);
    setCompanyBankAccounts([]);
    setLoading(false);
    setError(null);
    navigate("/login");
  };

  const googleLogin = () => {
    handleGoogleSignIn();
  };

  const handleGoogleCallback = async () => {
    setLoading(true);
    console.log("Google callback initiated...");

    const searchParams = new URLSearchParams(window.location.search);

    setLoading(true);

    const code = searchParams.get("code");
    const token = searchParams.get("token");
    const refreshToken = searchParams.get("refreshToken");
    const navigationParam = Cookies.get("navigation");

    console.log("Search Params:", {
      code,
      token,
      refreshToken,
      navigationParam,
    });

    try {
      // Handle the authorization code flow
      if (code) {
        console.log("Handling code exchange with code:", code);
        const data = await handleCodeExchange(code);
        const { id_token, refresh_token } = data;

        console.log("Code exchange result:", { id_token, refresh_token });

        if (!id_token || !refresh_token) {
          throw new Error("Failed to retrieve tokens during code exchange.");
        }

        sessionStorage.setItem(STORAGE_TOKEN, id_token);
        sessionStorage.setItem(STORAGE_REFRESH_TOKEN, refresh_token);

        console.log("Tokens saved in session storage");

        const { userId } = await handleTokenAndFetchUserData(id_token, setRole);
        console.log("User data fetched with userId:", userId);

        await fetchData(userId);

        // Navigate based on the `navigationParam` or default behavior
        const targetPath =
          navigationParam === "freeInvoice" ? "/new-user" : "/dashboard";
        console.log("Navigating to:", targetPath);
        navigate(targetPath);

        // Clean the URL by removing the 'code' parameter
        const cleanUrl = window.location.href.split("?")[0]; // Get clean URL without query params
        window.history.replaceState({}, document.title, cleanUrl); // Replace the URL without query params
      }
      // Handle direct token flow (used for refresh-based logins)
      else if (token && refreshToken) {
        console.log("Handling direct token flow:", { token, refreshToken });
        sessionStorage.setItem(STORAGE_TOKEN, token);
        sessionStorage.setItem(STORAGE_REFRESH_TOKEN, refreshToken);

        console.log("Tokens saved in session storage");

        const { userId } = await handleTokenAndFetchUserData(token, setRole);
        console.log("User data fetched with userId:", userId);
        await fetchData(userId);
        console.log("Navigating to dashboard...");
        navigate("/dashboard");
      } else {
        console.warn("Neither code nor token found in query parameters.");
      }
    } catch (error: any) {
      console.error("Error in Google callback handling:", error.message, error);
    } finally {
      console.log("Google callback handling completed.");
      setLoading(false);
    }
  };

  /**
   * Modified register function that registers the user with Cognito
   * and can optionally fetch user data or handle post-registration steps.
   * @param username
   * @param password
   * @param attributes
   */
  const register = async (
    username: string,
    password: string,
    attributes: CognitoUserAttribute[],
  ): Promise<void> => {
    setLoading(true);
    setError(null);

    try {
      await cognitoRegister(username, password, attributes);
      navigate("/confirm-account");
    } catch (err: any) {
      setError(err.message || "An unknown error occurred during registration.");
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authError) {
      setError(authError);
    }
  }, [authError]);

  return (
    <UseAuthContext.Provider
      value={{
        user: session,
        loading,
        error,
        login,
        googleLogin,
        handleGoogleCallback,
        logout,
        register,
      }}
    >
      {children}
      {/* Business Type Selector Modal */}
      <Modal
        visible={businessModalVisible}
        onClose={() => setBusinessModalVisible(false)}
      >
        <BusinessTypeSelector onClose={() => setBusinessModalVisible(false)} />
      </Modal>

      {!isPasswordChanged && !isRootUser && isPasswordModalShown && (
        <SubscriptionModal>
          <ChangePassword
            data-testid="change-password-modal"
            onClose={() => setIsPasswordModalShown(false)}
          />
        </SubscriptionModal>
      )}
    </UseAuthContext.Provider>
  );
};
