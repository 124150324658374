import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IInvoice } from "../../../../@types";
import { Annotation } from "../../../../@types/annotation";
import { ClipIcon } from "../../../../assets/icons/Clip";
import { DownloadIcon } from "../../../../assets/icons/DownloadIcon";
import { PrintIcon } from "../../../../assets/icons/PrintIcon";
import RecordIcon from "../../../../assets/icons/RecordIcon";
import SendIcon from "../../../../assets/icons/SendIcon";
import { downloadReceipt } from "../../../../backend-services/receipt";
import { Statuses } from "../../../../constants";
import useAnnotations from "../../../../hooks/annotation/useAnnotations";
import { useDownloadInvoice } from "../../../../hooks/invoice/useDownloadInvoice";
import { useUserStore } from "../../../../state/useUserStore";
import { formatMoney, getInvoiceStatus } from "../../../../utils";
import {
  invoiceToReceiptData,
  ReceiptData,
} from "../../../../utils/EscPosEncoder";
import { isNotAccountant } from "../../../../utils/isNotAccountant";
import { useQuery } from "../../../../utils/UrlUtils";
import { CenterModal } from "../../../CenterModal";
import { Tab } from "../../../common";
import AnnotationComponent from "../../../common/Annotation/AnnotationComponent";
import SuccessBanner from "../../../common/Banner/SuccessBanner";
import { GroupButton } from "../../../common/GroupButtons";
import ViewInvoiceTour from "../../../common/JoyRide/ViewInvoiceTour";
import ThermalPrinter from "../../../common/printer";
import {
  ManualRecordPayment,
  UploadTransactionEvidence,
} from "../../../payments";
import RecordPaymentSuccess from "../../../payments/RecordPayment/SuccessModal";
import { InvoiceDocument } from "../../SalesDocument";
import DownloadLoader from "../../SalesDocument/DownloadLoader";
import InvoiceDownloadNotification from "../DownloadNotifcation";
import PaymentHistoryTable from "../payment/PaymentHistoryTable";
import { FailedRCVerifiicationModal } from "../PaymentLink/FailedRCVerification";
import { FailedVerifiicationModal } from "../PaymentLink/FailedVerification";
import { GeneratePaymentPrompt } from "../PaymentLink/GeneratePayment";
import PaymentLinkInfoModal from "../PaymentLink/PaymentInfoModal";
import { VerficationModal } from "../PaymentLink/VerificationModal";
import SendReceiptModal from "../SendReceiptModal";
import SendReminderModal from "../SendReminderModal";
import Header from "./Header";
import DropdownButton from "./ReminderActions";
import { isAdmin } from "../../../../utils/isAdmin";
import { CancelConfirmationPrompt } from "../CancelConfirmation";
import { RefundConfirmationPrompt } from "../RefundConfirmationPrompt";
import { CloseIcon } from "../../../../assets/icons";
import { useCompanyStore } from "../../../../state/useCompanyStore";
import { getStatusClass, getStatusColor } from "../Status/status";

interface ViewInvoiceProps {
  invoice: IInvoice | undefined;
  annotations: Annotation[];
  handleReOccurringInvoice: any;
  handleCancelInvoice: any;
  handleRefundInvoice: any;
  isRefunding: boolean;
  customerId: string;
}
const ViewInvoice: React.FC<ViewInvoiceProps> = ({
  invoice,
  handleReOccurringInvoice,
  handleCancelInvoice,
  handleRefundInvoice,
  isRefunding,
  customerId,
  annotations,
}) => {
  const { t } = useTranslation("invoices");
  const params = useParams();
  const invoiceId = params.invoiceNo;
  const query = useQuery();
  const downloadButtonRef = React.useRef<HTMLAnchorElement>(null);
  const freeForm = query.get("freeForm");
  const isFirstTimer = freeForm === "freeForm";
  const { user, roles } = useUserStore();
  const userStore = useCompanyStore();
  const isRetail = userStore.isRetailBusiness();
  const urlParams = new URLSearchParams(window.location.search);
  const showPaymentParams = Object.fromEntries(urlParams.entries());
  const [isDownloading, setIsDownloading] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showInformationPrompt, setShowInformationPrompt] =
    React.useState(false);
  const [showRecordPayment, setShowRecordPayment] = React.useState(false);
  const [reloadSignal, setReloadSignal] = useState(0);
  const [, setLoading] = React.useState(false);
  const [showDownloadDirection, setShowDownloadDirection] = useState(true);
  const [selectedInvoice, setSelectedInvoice] = useState<IInvoice | null>(null);
  const [printCounter, setPrintCounter] = useState(0);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showPaymentPrompt, setShowPaymentPrompt] = React.useState(false);
  const [showVerificationPrompt, setShowVerificationPrompt] =
    React.useState(false);
  const [showFailedVerificationPrompt, setShowFailedVerificationPrompt] =
    React.useState(false);
  const [showFailedRCVerificationPrompt, setShowFailedRCVerificationPrompt] =
    React.useState(false);
  const [showReminder, setShowReminder] = useState(false);
  const [, setIsCancelled] = React.useState(false);
  const [isSending, setIsSending] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const [receipt, setReceipt] = useState<ReceiptData>();
  const [success, setSuccess] = React.useState(false);
  const annotationsData = useAnnotations(annotations, "Invoice");
  const [isDropReminderOpen, setIsDropReminderOpen] = useState(false);
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [showRefundPrompt, setShowRefundPrompt] = React.useState(false);

  React.useEffect(() => {
    if (isFirstTimer && (isDownloading || showDownloadModal)) {
      window.scrollTo(0, 0);
    }
  }, [isFirstTimer, isDownloading, showDownloadModal]);

  const handleGeneratePaymentLink = () => {
    setShowInformationPrompt(true);
  };
  const handleNewPayment = () => {
    setReloadSignal((prevSignal) => prevSignal + 1);
  };

  const handleNewUploadPayment = async (isUploadedPayment: boolean) => {
    if (isUploadedPayment && params && params.invoiceNo) {
      setLoading(true);
      setReloadSignal((prevSignal) => prevSignal + 1);
    }
  };

  const handleDownloadReceipt = async (id: string) => {
    try {
      setIsDownloading(true);
      const response = await downloadReceipt(id);
      console.log(response);
      const blob = await fetch(response.url).then((res) => res.blob());
      const a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = `Invoice-${invoiceId}.pdf`;
      setIsDownloading(false);
      a.click();
    } catch (error) {
      console.error(error);
    }
  };
  const handleUpdate = (updatedInvoice: IInvoice) => {
    setSelectedInvoice(updatedInvoice);
  };

  const { downloadPDF, isLoading } = useDownloadInvoice({
    //@ts-ignore
    invoice: document,
    //@ts-ignore
    invoiceId: invoiceId,
    onUpdate: handleUpdate,
  });

  const handlePreviewCopyClick = async () => {
    if (isDownloading) return;
    setIsDownloading(true);
    try {
      if (invoice?.status !== Statuses.PAID.toLowerCase()) {
        await downloadPDF();
      } else if (invoiceId) {
        handleDownloadReceipt(invoiceId);
      }
    } catch (error) {
      console.error("Error handling download or preview:", error);
    } finally {
      setIsDownloading(false);

      if (isFirstTimer) {
        setShowDownloadDirection(false);
        setShowDownloadModal(true);
      }
    }
  };

  const handleDownloadCopyClick = async () => {
    if (isDownloading) return;
    setIsDownloading(true);

    try {
      if (invoice?.status !== Statuses.PAID.toLowerCase()) {
        await downloadPDF();
      } else if (invoiceId) {
        handleDownloadReceipt(invoiceId);
      }
    } catch (error) {
      console.error("Error handling download or preview:", error);
    } finally {
      setIsDownloading(false);

      if (isFirstTimer) {
        setShowDownloadDirection(false);
        setShowDownloadModal(true);
      }
    }
  };

  const triggerSendModal = () => {
    setShowSendModal(true);
  };

  const triggerPrint = () => {
    setPrintCounter((prevCounter) => prevCounter + 1);
  };

  const handleCancelInvoiceWithSuccessModal = async () => {
    try {
      await handleCancelInvoice();
      setIsCancelled(true);
    } catch (error) {
      toast.error("Failed to cancel the invoice");
    }
  };
  const handleGenerateFailedLink = () => {
    setShowPaymentPrompt(false);
    setShowFailedRCVerificationPrompt(false);
    setShowFailedVerificationPrompt(true);
  };

  const handleGenerateFailedRCLink = () => {
    setShowPaymentPrompt(false);
    setShowFailedVerificationPrompt(false);
    setShowFailedRCVerificationPrompt(true);
  };

  const handleGenerateVerificationLink = () => {
    setShowPaymentPrompt(false);
    setShowVerificationPrompt(true);
  };

  const handleShowPaymentPrompt = (show: boolean) => {
    setShowVerificationPrompt(true);
    setShowFailedRCVerificationPrompt(false);
  };

  const handleValidateSuccess = () => {
    // Handle the validated data here
    setShowVerificationPrompt(false);
    setShowFailedVerificationPrompt(false);
    setShowFailedRCVerificationPrompt(false);
    setShowPaymentPrompt(true);
  };

  const handleActivatePaymentLink = () => {
    setShowInformationPrompt(false);
    const validatedBankExists = user.bankAccounts?.some(
      (account: { isValidated: any }) => account.isValidated,
    );
    if (!validatedBankExists) {
      setShowVerificationPrompt(true);
      return;
    }

    if (user.isVerified) {
      setShowPaymentPrompt(true);
    } else {
      setShowVerificationPrompt(true);
    }
  };

  const closeUploadModal = () => {
    setShowRecordPayment(false);
  };

  React.useEffect(() => {
    if (showPaymentParams.action === "showPayment") {
      setShowRecordPayment(true);
    }
  }, [showPaymentParams.action]);

  React.useEffect(() => {
    if (user && invoice) {
      const receipt = invoiceToReceiptData(invoice, user, isRetail);
      setReceipt(receipt);
    }
  }, [user]);

  React.useEffect(() => {
    if (downloaded || isSending) {
      const timer: number = window.setTimeout(() => {
        setDownloaded(false);
        setIsSending(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [downloaded, isSending]);

  const handleReminder = () => {
    setIsDropReminderOpen(true);
  };
  const handleReminderMobile = () => {
    setIsDropReminderOpen(false);
    setShowReminder(true);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropReminderOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return invoice ? (
    <>
      {isFirstTimer && isDownloading && <DownloadLoader />}
      {isFirstTimer && showDownloadModal && (
        <div className="absolute">
          <ViewInvoiceTour />
        </div>
      )}
      {isSending && <SuccessBanner message="Receipt sent successfully" />}
      {downloaded && <SuccessBanner message="Receipt download successful" />}
      <ThermalPrinter receipt={receipt} printCounter={printCounter} />

      <Header
        invoice={invoice}
        annotations={annotations}
        isRefunding={isRefunding}
        handleCancelInvoice={handleCancelInvoice}
        handleRefundInvoice={handleRefundInvoice}
      />

      {typeof invoice.status === "string" &&
        invoice.status.toLowerCase() !== Statuses.PAID.toLowerCase() && (
          <div className="hidden md:block z-0">
            {/* <div className="flex menu-item-11 " onClick={handleGeneratePaymentLink} style={{ cursor: 'pointer' }}> */}
            <div
              className="flex  "
              onClick={handleGeneratePaymentLink}
              style={{ cursor: "pointer" }}
            >
              <p className="text-[#044E97] text-[16px] ml-4">
                Generate Payment Link
              </p>
              <div className=" ml-1">
                <ClipIcon />
              </div>
            </div>
          </div>
        )}

      <div className="border border-gray-100 px-1 rounded-t-[12px] mt-[2em] lg:hidden md:hidden ">
        <div className="justify-between mb-4">
          <div className="flex flex-col justify-start ">
            <p className="font-semibold text-[18px] pl-2 mt-2">INVOICE</p>
            <span className="font-medium text-[16px] text-left pl-2">
              {invoice.invoiceNumber}
            </span>
          </div>
          <div className="flex justify-end gap-2 mt-[-2.5em]">
            <span
              style={{ backgroundColor: getStatusColor(invoice.status) }}
              className={`${getStatusClass(
                invoice.status,
              )} pt-1 px-1 text-center rounded-sm text-[14px]`}
            >
              {getInvoiceStatus(invoice.status)}
            </span>
            <AnnotationComponent
              type="invoice"
              annotationData={annotationsData}
            />
          </div>
        </div>
        <div className="flex justify-start">
          {invoice.currency && invoice.currency !== "NGN" && (
            <p className="text-[14px] mt-[em] text-left">
              {t("exchangeRate")}:{formatMoney(invoice.exchangeRate)} per{" "}
              {invoice.currency}
            </p>
          )}
        </div>
        {typeof invoice.status === "string" &&
          invoice.status.toLowerCase() !== Statuses.PAID.toLowerCase() && (
            <div
              className="flex lg:hidden md:hidden menu-item-11 z-0 justify-end mt-[1em] mb-3"
              onClick={handleGeneratePaymentLink}
              style={{ cursor: "pointer" }}
            >
              <div className=" ml-1">
                <ClipIcon />
              </div>
              <p className="text-[#044E97] text-[14px]">
                Generate Payment Link
              </p>
            </div>
          )}
      </div>
      <div
        className="hidden lg:block md:block pr-[1em] mb-0 mt-[1em]"
        data-testid="invoice-document"
      >
        <InvoiceDocument
          type="invoice"
          document={invoice}
          address={user.address}
          bankData={user.bankData}
          brandColor={user.brandColor}
        />
      </div>
      <div className="lg:hidden md:hidden  mb-0 mt-[1em]">
        <InvoiceDocument
          type="invoice"
          document={invoice}
          address={user.address}
          bankData={user.bankData}
          brandColor={user.brandColor}
        />
      </div>
      {isNotAccountant(roles) && (
        <div className="z-100 mx-auto w-full py-4 lg:hidden  mt-[-1em] md:mt-[1em]">
          <div className="relative flex justify-center text-center gap-2 flex-wrap">
            {![
              Statuses.CANCELLED.toLowerCase(),
              Statuses.PAID.toLowerCase(),
              Statuses.REFUNDED.toLowerCase(),
            ].includes(invoice.status) && (
              <button
                className="w-[100px] text-[14px] h-[48px] font-normal rounded-[4px] text-center flex flex-col items-center justify-center gap-1 text-[#044E97] bg-white cursor-pointer"
                onClick={() => setShowRecordPayment(true)}
              >
                <RecordIcon />
                <span className="whitespace-nowrap">Record Payment</span>
              </button>
            )}
            {invoice.status !== Statuses.PAID.toLowerCase() ? (
              <>
                <button
                  className={`w-[100px] text-[14px] h-[48px] font-normal rounded-[4px] text-center flex flex-col items-center justify-center gap-1 ${
                    invoice.status === Statuses.CANCELLED.toLowerCase()
                      ? "text-gray-400 bg-gray-200 cursor-not-allowed"
                      : "text-[#044E97] bg-white"
                  }`}
                  onClick={handleReminderMobile}
                  disabled={invoice.status === Statuses.CANCELLED.toLowerCase()}
                >
                  <SendIcon />
                  <span>Reminder</span>
                </button>

                <button
                  className={`w-[100px] h-[48px] text-[14px] font-normal rounded-[4px] text-center flex flex-col items-center justify-center gap-1 ${
                    isDownloading
                      ? "cursor-not-allowed text-[#A4A4A4] bg-[#F6F6F6]"
                      : "cursor-pointer text-[#044E97] bg-white"
                  }`}
                  onClick={handlePreviewCopyClick}
                  disabled={isDownloading}
                >
                  <DownloadIcon />
                  <span className="whitespace-nowrap">
                    {isDownloading ? "Loading..." : "Download"}
                  </span>
                </button>
              </>
            ) : (
              <>
                <button
                  className="w-[100px] h-[48px] text-[14px] font-normal rounded-[4px] text-center flex flex-col items-center justify-center gap-1 text-[#044E97] bg-white"
                  onClick={triggerSendModal}
                >
                  <SendIcon />
                  <span>Share Receipt</span>
                </button>
                <button
                  className="w-[100px] h-[48px] text-[14px] font-normal rounded-[4px] text-center flex flex-col items-center justify-center gap-1 text-[#044E97] bg-white"
                  onClick={triggerPrint}
                >
                  <PrintIcon />
                  <span>Print Receipt</span>
                </button>
                <button
                  className={`w-[100px] text-[14px] h-[48px] font-normal rounded-[4px] text-center flex flex-col items-center justify-center gap-1 ${
                    isDownloading
                      ? "cursor-not-allowed text-[#A4A4A4] bg-[#F6F6F6]"
                      : "cursor-pointer text-[#044E97] bg-white"
                  }`}
                  onClick={handlePreviewCopyClick}
                  disabled={isDownloading}
                >
                  <DownloadIcon />
                  <span className="whitespace-nowrap">
                    {isDownloading ? "Loading..." : "Download Receipt"}
                  </span>
                </button>
              </>
            )}
          </div>
        </div>
      )}

      <div className="flex w-[100%] lg:pr-[2em]">
        <div className=" w-[100%]  lg:mt-[3em] mt-[1em]">
          <PaymentHistoryTable reloadSignal={reloadSignal} />
        </div>
      </div>
      {/* <div className='flex'> */}

      {isNotAccountant(roles) && (
        <div className=" hidden lg:block  z-100 mx-auto w-[100%] py-[20px] sticky bottom-[0] mt-[32px] bg-[#F2F4F7] border border-[#D6DADD] pl-[32px] pr-[2em]">
          <div className="flex justify-center">
            <div className="relative flex text-center justify-center gap-2 ">
              <div className="border border-[#044E97] rounded-lg pt-1 ">
                <GroupButton
                  buttons={[
                    {
                      text: "Record Payment",
                      icon: <RecordIcon />,
                      onClick: () => setShowRecordPayment(true),
                      disabled:
                        invoice.status === Statuses.CANCELLED.toLowerCase() ||
                        invoice.status === Statuses.PAID.toLowerCase() ||
                        invoice.status === Statuses.REFUNDED.toLowerCase(),
                    },
                  ]}
                />
              </div>
              {invoice.status !== Statuses.PAID.toLowerCase() ? (
                <div className="text-[16px] flex gap-2 ">
                  <div className="border border-[#044E97] rounded-lg pt-1">
                    <GroupButton
                      buttons={[
                        {
                          text: "Send Reminder",
                          icon: <SendIcon />,
                          onClick: handleReminder,
                          disabled:
                            invoice.status === Statuses.CANCELLED.toLowerCase(),
                        },
                      ]}
                    />
                  </div>
                  <div className="border border-[#044E97] rounded-lg pt-1">
                    <GroupButton
                      buttons={[
                        {
                          text: "Print",
                          icon: <PrintIcon />,
                          onClick: triggerPrint,
                        },
                      ]}
                    />
                  </div>
                  <div className="border border-[#044E97] rounded-lg pt-1">
                    <GroupButton
                      buttons={[
                        {
                          text: isDownloading ? "Downloading..." : "Download",
                          icon: <DownloadIcon />,
                          onClick: handlePreviewCopyClick,
                          disabled: isDownloading,
                          ref: downloadButtonRef,
                        },
                      ]}
                    />
                  </div>
                </div>
              ) : (
                <div className="text-[16px] flex gap-2 ">
                  <div className="border border-[#044E97] rounded-lg pt-1">
                    <GroupButton
                      buttons={[
                        {
                          text: "Send Receipt",
                          icon: <SendIcon />,
                          onClick: triggerSendModal,
                          // 'data-testid': 'send-receipt-button',
                        },
                      ]}
                    />
                  </div>
                  <div className="border border-[#044E97] rounded-lg pt-1">
                    <GroupButton
                      buttons={[
                        {
                          text: t("printReceipt"),
                          icon: <PrintIcon />,
                          onClick: triggerPrint,
                        },
                      ]}
                    />
                  </div>
                  <div className="border border-[#044E97] rounded-lg pt-1">
                    <GroupButton
                      buttons={[
                        {
                          text: isDownloading
                            ? "Downloading..."
                            : "Download Receipt",
                          icon: <DownloadIcon />,
                          onClick: handleDownloadCopyClick,
                          disabled: isDownloading,
                          ref: downloadButtonRef,
                        },
                      ]}
                    />
                  </div>
                </div>
              )}
            </div>
            {invoice.status !== Statuses.PAID.toLowerCase() && (
              <div className="menu-item-13 flex justify-end mt-[em]">
                <button
                  className={`text-[red]  border border-[red] ml-[0.5em] rounded-lg w-[110px] h-[50px] flex pt-3 pl-4 ${
                    invoice.status === Statuses.CANCELLED.toLowerCase() ||
                    invoice.status === Statuses.REFUNDED.toLowerCase()
                      ? " text-gray-500 cursor-not-allowed"
                      : "cursor-pointer "
                  }`}
                  onClick={() => setShowPrompt(true)}
                  disabled={
                    invoice.status === Statuses.CANCELLED.toLowerCase() ||
                    invoice.status === Statuses.REFUNDED.toLowerCase()
                  }
                >
                  Cancel
                  <span className="ml-[-0.5em] mt-[0.1em]">
                    {" "}
                    <CloseIcon />
                  </span>
                </button>
              </div>
            )}
            {isAdmin(roles) &&
              invoice.status === Statuses.PAID.toLowerCase() && (
                <div className="flex justify-end  mt-[em]">
                  <button
                    className={`text-[#044E97] border border-[#044E97] rounded-lg ml-[0.5em] w-[110px] h-[50px] flex pt-4 pl-4 ${
                      isRefunding ||
                      invoice.status !== Statuses.PAID.toLowerCase()
                        ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                        : "cursor-pointer"
                    }`}
                    onClick={() => setShowRefundPrompt(true)}
                    disabled={
                      isRefunding ||
                      invoice.status !== Statuses.PAID.toLowerCase()
                    }
                  >
                    Refund
                    <span className="ml-[0.5em] mt-[0.1em]">
                      {" "}
                      <RecordIcon />
                    </span>
                  </button>
                </div>
              )}
          </div>
        </div>
      )}
      {showDownloadDirection && isFirstTimer && (
        <div className="absolute xl:left-[40em] biglg:left-[40em] lg:left-[30em] mt-[-1em]">
          <InvoiceDownloadNotification />
        </div>
      )}
      {isDropReminderOpen && (
        <div className="sticky  bottom-[0] " ref={dropdownRef}>
          <DropdownButton
            invoice={invoice}
            onOpenPopup={isDropReminderOpen}
            invoiceId={invoiceId}
            close={() => setIsDropReminderOpen(false)}
          />
        </div>
      )}
      {showRecordPayment && (
        <div className="">
          <Tab
            items={[
              {
                title: t("recordManually"),
                component: (
                  <ManualRecordPayment
                    onRecordEntity={handleNewPayment}
                    entity={invoice}
                    invoiceId={invoice.id}
                    balanceDue={invoice.balanceDue}
                    setSuccess={setSuccess}
                    closeModal={closeUploadModal}
                  />
                ),
              },
              {
                title: t("uploadReceipt"),
                component: (
                  <UploadTransactionEvidence
                    entity={invoice}
                    onRecordEntity={handleNewUploadPayment}
                    paymentType="revenue"
                    closeModal={closeUploadModal}
                  />
                ),
              },
            ]}
          />
        </div>
      )}
      {success && (
        <RecordPaymentSuccess
          invoiceId={invoiceId}
          handleDownload={handleDownloadReceipt}
          setSuccess={setSuccess}
          setShowSendModal={setShowSendModal}
          triggerPrint={triggerPrint}
        />
      )}

      {showPaymentPrompt && (
        <CenterModal>
          <GeneratePaymentPrompt
            onGenerate={handleCancelInvoiceWithSuccessModal}
            onClose={() => setShowPaymentPrompt(false)}
            showPaymentPrompt={showPaymentPrompt}
            showFailedVerificationPrompt={handleGenerateFailedLink}
            showFailedRCVerificationPrompt={handleGenerateFailedRCLink}
            showVerificationPrompt={handleGenerateVerificationLink}
            //@ts-ignore
            customer={invoice.customer}
            balanceDue={invoice.balanceDue}
            currency={invoice.currency}
            invoiceId={invoiceId}
            bankAccounts={user.bankData}
          />
        </CenterModal>
      )}
      {showVerificationPrompt && (
        <CenterModal>
          <VerficationModal
            onGenerate={handleGenerateVerificationLink}
            onClose={() => setShowVerificationPrompt(false)}
            showPaymentPrompt={handleShowPaymentPrompt}
            showVerificationPrompt={showVerificationPrompt}
            invoiceId={invoiceId}
            bankAccounts={user.bankData}
            accountIndex={0}
            onValidateSuccess={handleValidateSuccess}
            showFailedVerificationPrompt={handleGenerateFailedLink}
            showFailedRCVerificationPrompt={handleGenerateFailedRCLink}
          />
        </CenterModal>
      )}
      {showFailedVerificationPrompt && (
        <CenterModal>
          <FailedVerifiicationModal
            onFailed={handleGenerateFailedLink}
            onClose={() => setShowFailedVerificationPrompt(false)}
            showFailedVerificationPrompt={showFailedVerificationPrompt}
            invoiceId={invoiceId}
            bankAccounts={user.bankData}
            showPaymentPrompt={handleValidateSuccess}
          />
        </CenterModal>
      )}
      {showFailedRCVerificationPrompt && (
        <CenterModal>
          <FailedRCVerifiicationModal
            onFailed={handleGenerateFailedRCLink}
            onClose={() => setShowFailedRCVerificationPrompt(false)}
            showFailedRCVerificationPrompt={showFailedRCVerificationPrompt}
            invoiceId={invoiceId}
            onValidateSuccess={handleValidateSuccess}
            showVerificationPrompt={handleShowPaymentPrompt}
          />
        </CenterModal>
      )}

      {showInformationPrompt && (
        <PaymentLinkInfoModal
          onClose={() => setShowInformationPrompt(false)}
          onGenerate={handleActivatePaymentLink}
          isOpen={true}
        />
      )}
      {showReminder && (
        <SendReminderModal
          setIsSending={setIsSending}
          invoiceId={invoiceId}
          //@ts-ignore
          customer={invoice?.customer}
          setShowSendModal={setShowReminder}
        />
      )}
      {showSendModal && (
        <SendReceiptModal
          setIsSending={setIsSending}
          invoiceId={invoiceId}
          customer={invoice?.customer}
          setShowSendModal={setShowSendModal}
        />
      )}
      {showPrompt && (
        <CancelConfirmationPrompt
          onCancel={handleCancelInvoiceWithSuccessModal}
          onClose={() => setShowPrompt(false)}
          isOpen={showPrompt}
        />
      )}
      {showRefundPrompt && (
        <RefundConfirmationPrompt
          onRefund={handleRefundInvoice}
          onClose={() => setShowRefundPrompt(false)}
          isOpen={showRefundPrompt}
        />
      )}
    </>
  ) : null;
};

export { ViewInvoice };
